@import "./src/assets/scss/main.scss";









































































































.admin-order-wrapper {
    background: #F5F5F5;
}

.adminOrder {
    background: #F5F5F5;
    padding-top: 25px;
    padding-bottom: 3px;

    &__header {
        display: flex;
        justify-content: space-between;
        //align-items: center;
        font-family: Montserrat;
    }

    &__header-number {
        font-size: 16px;
        margin-bottom: 17px;

        p {
            padding: 0;
            margin: 0;
        }
    }

    &__header-time {
        font-size: 18px;
        color: #000;
        font-weight: 700;
    }

    .orange {
        color: $orange;
    }

    &__btn {
        margin-top: 20px;
        padding-bottom: 29px;
    }
}

.showFullOrder {
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat;
    cursor: pointer;
}

.adminOrderItem {
    display: flex;
    font-family: Montserrat;
    background: #fff;
    opacity: .5;
    padding: 15px;
    height: 102px;
    border-radius: 9px;
    min-width: 234px;
    align-items: center;
    margin-bottom: 10px;

    &__number {
        width: 88px;
        height: 72px;
        background: #000;
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &--orange {
            background: $orange;
        }

        &--blue {
            background: #0041E8;
        }
    }

    &__desc {
        padding-left: 13px;

        b {
            display: block;

        }

        span {
            display: block;
        }

        &--center {
            width: 100%;
            text-align: center;
        }
    }
}
